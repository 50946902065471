﻿
.body-container {
    height: 100%;
}

.page-content-container {
    padding: 20px;
}

body {
    .main-menu-container {
        display: none;
    }

    &.menu-is-pinned {
        @media screen and (min-width: $screen-lg-min) {
            .main-menu-container {
                display: block;
            }

            .body-container, footer {
                margin-left: $width-sidebar-menu;
            }

            header > .menu-trigger {
                display: none;
            }

            @media screen and (max-width: $screen-md-min + $width-sidebar-menu) {
                .layout-panel-container, .inline-form-groups {
                    > [class^="layout-panel-"], > [class^="form-group-"] {
                        width: 100% !important;
                    }
                }
            }
        }

        .main-menu-container {
            .pin-menu-button {
                .icon-pin {
                    @extend .fas;
                }
            }
        }
    }
}
