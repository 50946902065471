﻿.form-control {
    @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);
    @include box-shadow(none);
    @include form-control-focus($color-primary);
    padding: $padding-input;
    height: $height-input;
    border: 1px solid $color-input-border;
    background: $color-input;
    color: $color-input-fg;
    border-radius: $border-radius-input;

    &[type="color"] {
        padding: 0 3px;
    }

    &.pure-inline {
        @include box-shadow(none);
        border: none;
        background-color: transparent;
        color: inherit;
        padding: 0;
        height: auto;
        min-height: auto;
        resize: none;
    }
}

.mce-lite.mce-content-body {
    @extend .form-control;
    border: 1px solid $color-input-border;
    height: auto;
    min-height: $height-input * 3;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
select,
textarea {
    @extend .form-control;

    &.datepicker,
    &.datetimepicker,
    option {
        background-color: $color-input;
    }
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
select,
textarea,
.mce-lite.mce-content-body,
.selectize-input,
input.datepicker,
input.datetimepicker {
    &[readonly] {
        background-color: $color-input-readonly !important;

        &.pure-inline {
            background-color: transparent !important;
        }

        &:hover {
            cursor: default;
        }
    }
}

.form-group {
    label {
        display: block;
        font-weight: bold;

        ~ label.sub {
            font-weight: lighter;
            margin-top: -8px;
        }

        + button.field-history {
            position: absolute;
            top: 12px;
            right: 8px;
            border-radius: 50%;
            font-size: 12px;
            padding: 1px 3px;
        }
    }

    .editor-for-bool {
        &:first-of-type {
            margin-top: 20px;
        }

        label {
            display: inline-block;
            margin-bottom: 10px;
            position: relative;
            top: 3px;
            left: 25px;
            padding-left: 0;

            &:hover {
                cursor: pointer;
            }
        }

        input[type="checkbox"],
        input[type="radio"] {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            margin-left: 0;

            &:hover {
                cursor: pointer;
            }

            &[readonly],
            &[readonly] + label {
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }

    &.checkbox,
    &.radio {
        .editor-for-bool {
            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    &.checkbox,
    &.radio {
        margin: 10px 0 5px 0;
    }

    &.file-upload {
        .upload-container {
            + .field-validation-error {
                margin-top: 5px;
            }
        }
    }
}

.inline-form-groups {
    [class^=form-group-] {
        .form-group {
            label {
                @media screen and (min-width: $screen-sm-min) {
                    overflow-x: visible;
                    white-space: nowrap;
                }
            }

            &.checkbox,
            &.radio {
                @media screen and (min-width: $screen-sm-min) {
                    margin-top: 26px;
                }

                @media screen and (max-width: $screen-xs-max) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.field-validation-error {
    display: block;
    background: $color-danger;
    color: $color-danger-fg;
    padding: $padding-input;
}

input.input-validation-error,
textarea.input-validation-error,
select.input-validation-error,
.multiselect.input-validation-error + .btn-group button.multiselect {
    border-color: $color-danger !important;
    background-color: transparentize($color-danger, 0.75) !important;

    &:focus {
        @include form-control-focus($color-danger);
        background-color: transparent !important;
    }
}

.validation-summary-errors {
    color: darken($color-danger, 15%);
    background: lighten($color-danger, 40%);
    border: 1px solid $color-danger;
    padding: 8px 8px 8px 0;
    border-radius: 10px;

    ul {
        margin: 0;
    }
}

.timespan-editor {
    @include clearfix;

    .timespan-hours,
    .timespan-minutes {
        width: 48%;
        display: inline-block;

        select, label {
            display: inline-block;
        }

        select {
            width: 50%;
        }

        label {
            font-weight: normal;
            margin-left: 5px;
        }
    }

    .timespan-hours {
        float: left;
    }

    .timespan-minutes {
        float: right;
    }

    .form-group.checkbox {
        clear: both;
        margin-top: 0 !important;

        label {
            font-weight: normal;
        }
    }
}

.dynamic-linked-entity-container,
.dynamic-entity-status-container {
    @include clearfix;

    .entity-pill {
        padding-right: 25px;

        &.entity-null {
            padding-right: 40px;
        }
    }

    .status-display {
        display: block;
        text-align: left;
        padding: 8px 40px 8px 12px;
        line-height: 1;
    }

    &.no-options {
        .entity-pill, .status-display {
            padding-right: 12px;
        }
    }

    &.no-write-permission {
        .entity-pill {
            padding-right: 0px;
        }

        .entity-null, .status-display {
            padding-right: 12px;
        }
    }

    button {
        @include desktop-dimmed;
        float: right;
        padding: 1px 5px;
        border-radius: 100px;
        margin-top: -28px;
        margin-right: 4px;
        position: relative;
    }

    &:hover {
        button {
            opacity: 1;
        }
    }

    .entity-select-container {
        height: $height-input;
    }
}

.additional-filters {
    @include make-row;

    .form-group {
        @include make-xs-column(12);
        @include make-sm-column(6);
        @include make-lg-column(4);
    }

    .submit-container {
        @include make-xs-column(12);
    }
}

.form-button-wrapper {
    display: inline-block;
    margin: 5px;
}

.document-create-container {
    @include clearfix;

    button.add {
        width: 49%;
        float: left;
        margin-bottom: 10px;

        &:nth-of-type(even) {
            margin-left: 2%;
        }
    }
}

.opportunity-details-panel {
    .description-textarea {
        min-height: 174px;
    }
}

.form-container {
    .xero-line-items {
        .form-group {
            @include clearfix;

            @media screen and (min-width: $screen-sm-min) {
                .sub, select {
                    display: inline-block;
                }

                .sub {
                    width: 35%;
                    float: right;
                    padding-top: 15px;
                    padding-left: 10px;
                }

                select {
                    width: 65%;
                    float: left;
                }
            }
        }
    }
}
