﻿
// RAW COLOURS - visit http://paletton.com/ for nice theme colours for this

$color-default: #EEEEEE;
$color-default-fg: #000000;
$color-primary: #5EB2F2;
$color-primary-fg: #FFFFFF;
$color-info: #6C6CF4;
$color-info-fg: #FFFFFF;
$color-success: #00CE76;
$color-success-fg: #FFFFFF;
$color-danger: #FF4E07;
$color-danger-fg: #FFFFFF;
$color-warning: #FFC107;
$color-warning-fg: #FFFFFF;


// SITE DEFAULT COLOURS

$color-body: #FFFFFF;
$color-body-fg: #333333;
$color-body-fg-subtle: lighten($color-body-fg, 40%);

$color-border: #A2A0A0;

$color-table-stripe1: $color-body;
$color-table-stripe2: darken($color-body, 10%);


// OTHER COLOURS

$color-info-stripe1: lighten($color-info, 20%);
$color-info-stripe2: lighten($color-info, 22.5%);
$color-info-stripe-fg: $color-body-fg;
$color-warning-stripe1: lighten($color-warning, 25%);
$color-warning-stripe2: lighten($color-warning, 40%);
$color-warning-stripe-fg: $color-body-fg;
$color-uat: $color-danger;


// SITE BODY VARIABLES

$font-family-body: 'Open Sans', sans-serif;
$font-weight-body: normal;
$font-family-title: 'Oswald', sans-serif;
$font-weight-title: normal;
$font-size-default: 14px;

$color-bg-a: #F0F0F0;
$color-bg-b: #FFFFFF;
$background-body: linear-gradient(110deg, $color-bg-a, $color-bg-b);

$width-sidebar-menu: 375px;

$border-radius-base: 0;
$border-radius-button: 4px;


// PANELS

$color-panel-header-fg: $color-body-fg;
$color-panel-content-fg: $color-body-fg;
$color-panel-footer-fg: $color-body-fg;

$color-panel-header: #D0D0D0;
$color-panel-content: $color-body;
$color-panel-footer: #EAEAEA;

$border-panel: none;

$border-panel-header: 1px solid $color-border;
$border-panel-content: 1px solid $color-border;
$border-panel-footer: 1px solid $color-border;

$padding-panel: 10px;

$box-shadow-panel: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);


// NOTES

$color-note-fg: $color-body-fg;
$color-note: #FFE79F;
$color-note-border: darken($color-note, 20%);
$color-note-icon: darken($color-note, 40%);


// FORMS

$padding-input: 5px 10px;
$height-input: 32px;

$padding-vert-button: 6px;
$padding-horiz-button: 12px;
$padding-vert-button-small: 1px;
$padding-horiz-button-small: 5px;

$color-input: #F2F2F2;
$color-input-fg: #000000;
$color-input-border: #EDEDED;
$border-radius-input: 0;

$color-input-readonly: darken($color-input, 5%);

$color-datepicker: #FFFFFF;
$color-datepicker-fg: #000000;

$border-additional-filters: $border-panel-footer;
$color-additional-filters: $color-panel-footer;
$color-additional-filters-fg: $color-panel-footer-fg;


// ENTITY PILLS

$color-contact: #CC9A06;
$color-contact-fg: #FFFFFF;
$color-opportunity: #720325;
$color-opportunity-fg: #FFFFFF;
$color-organisation: #0C0CDA;
$color-organisation-fg: #FFFFFF;
$color-product: #004D2C;
$color-product-fg: #FFFFFF;
$color-user: #AE5B25;
$color-user-fg: #FFFFFF;
$color-project: #21B073;
$color-project-fg: #FFFFFF;
$color-action: #A73C96;
$color-action-fg: #FFFFFF;
$color-appointment: #3B0084;
$color-appointment-fg: #FFFFFF;
$color-attachment: #174568;
$color-attachment-fg: #FFFFFF;
$color-address: #174568;
$color-address-fg: #FFFFFF;
$color-servicevisit: #474747;
$color-servicevisit-fg: #FFFFFF;
$color-servicevisitreport: #5E5F5E;
$color-servicevisitreport-fg: #FFFFFF;
$color-document: #0000AA;
$color-document-fg: #FFFFFF;
$color-servicecontract: #1CA474;
$color-servicecontract-fg: #FFFFFF;
$color-instrument: #028375;
$color-instrument-fg: #FFFFFF;


// GLOBAL SEARCH, SIDEBAR & MODALS

$color-globalsearch-content: $color-panel-content;
$color-globalsearch-content-fg: $color-panel-content-fg;

$color-entity-item-name: #FFFFFF;
$color-entity-item-name-fg: #000000;
$color-entity-item-details: #FFFFFF;
$color-entity-item-details-fg: #000000;

$color-entity-item-name-hover: #E0E0E0;
$color-entity-item-details-hover: #E0E0E0;

$border-entity-item: none;
$border-entity-item-inner: 1px solid $color-border;

$color-sidebar: $color-panel-content;
$color-sidebar-fg: $color-panel-content-fg;

$color-modal-header: $color-panel-header;
$color-modal-header-fg: $color-panel-header-fg;
$color-modal-content: $color-panel-content;
$color-modal-content-fg: $color-panel-content-fg;

$color-modal-backdrop: #000000;


// HEADER & FOOTER

$color-header: #333333;
$color-header-fg: #FFFFFF;
$color-footer: $color-panel-content;
$color-footer-fg: $color-panel-content-fg;
$border-footer: 1px solid $color-border;


// CALENDAR

$color-calendar-todayhighlight: lighten($color-info, 27.5%);

$color-calendarevent-busy: #B4DDFC;
$color-calendarevent-free: #F0F8FF;
$color-calendarevent-fg: #000000;

$color-calendarevent-outofoffice: #FFA682;
$color-calendarevent-outofoffice-fg: #000000;


// RAG STATUS

$color-rag-red: #CC0000;
$color-rag-red-fg: #FFFFFF;
$color-rag-amber: #CD6610;
$color-rag-amber-fg: #FFFFFF;
$color-rag-green: #008800;
$color-rag-green-fg: #FFFFFF;
