﻿
@mixin desktop-dimmed() {
    @media screen and (min-width: $screen-sm-min) {
        @include transition(opacity ease-in-out 0.15s);
        opacity: 0.2;
    }
}

@mixin desktop-invisible() {
    @media screen and (min-width: $screen-sm-min) {
        @include transition(opacity ease-in-out 0.15s);
        opacity: 0;
    }
}
