﻿body {
    font-family: $font-family-body;
    font-weight: $font-weight-body;
    color: $color-body-fg;
    font-size: $font-size-default;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: $font-family-title;
    font-weight: $font-weight-title;
}

h1, h2, h3, h4, h5, h6, div, span, p, b {
    &.primary {
        color: $color-primary;
    }

    &.info {
        color: $color-info;
    }

    &.success {
        color: $color-success;
    }

    &.danger {
        color: $color-danger;
    }

    &.warning {
        color: $color-warning;
    }
}
