﻿
.layout-panel-container,
.inline-form-groups {
    @include make-row;

    .layout-panel-full,
    .form-group-full,
    .form-group.full {
        @include make-md-column(12);
    }

    .layout-panel-threequarters,
    .form-group-threequarters,
    .form-group.threequarters {
        @include make-md-column(9);
    }

    .layout-panel-twothirds,
    .form-group-twothirds,
    .form-group.twothirds {
        @include make-md-column(8);
    }

    .layout-panel-half,
    .form-group-half,
    .form-group.half {
        @include make-md-column(6);
    }

    .layout-panel-third,
    .form-group-third,
    .form-group.third {
        @include make-md-column(4);
    }

    .layout-panel-quarter,
    .form-group-quarter,
    .form-group.quarter {
        @include make-md-column(3);
    }
}
