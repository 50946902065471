﻿/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation { 
	0% { opacity: 0; -webkit-animation-timing-function: ease-in; }
	8% { opacity: 1; -webkit-transform: scale(1.05); -webkit-animation-timing-function: ease-out; }
	17% { opacity: 1; -webkit-transform: scale(1.1); }
	25% { opacity: 0; -webkit-transform: scale(1.1); }
	100% { opacity: 0 }
}
@-moz-keyframes imageAnimation { 
	0% { opacity: 0; -moz-animation-timing-function: ease-in; }
	8% { opacity: 1; -moz-transform: scale(1.05); -moz-animation-timing-function: ease-out; }
	17% { opacity: 1; -moz-transform: scale(1.1); }
	25% { opacity: 0; -moz-transform: scale(1.1); }
	100% { opacity: 0 }
}
@-o-keyframes imageAnimation { 
	0% { opacity: 0; -o-animation-timing-function: ease-in; }
	8% { opacity: 1; -o-transform: scale(1.05); -o-animation-timing-function: ease-out; }
	17% { opacity: 1; -o-transform: scale(1.1); }
	25% { opacity: 0; -o-transform: scale(1.1); }
	100% { opacity: 0 }
}
@-ms-keyframes imageAnimation { 
	0% { opacity: 0; -ms-animation-timing-function: ease-in; }
	8% { opacity: 1; -ms-transform: scale(1.05); -ms-animation-timing-function: ease-out; }
	17% { opacity: 1; -ms-transform: scale(1.1); }
	25% { opacity: 0; -ms-transform: scale(1.1); }
	100% { opacity: 0 }
}
@keyframes imageAnimation { 
	0% { opacity: 0; animation-timing-function: ease-in; }
	8% { opacity: 1; transform: scale(1.05); animation-timing-function: ease-out; }
	17% { opacity: 1; transform: scale(1.1); 	}
	25% { opacity: 0; transform: scale(1.1); 	}
	100% { opacity: 0 }
}

.cb-slideshow {
    list-style: none;
}

.cb-slideshow,
.cb-slideshow:after {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.cb-slideshow:after {
    content: '';
    background: transparent url('/Content/Images/login/pattern.png') repeat top left;
}

.cb-slideshow li span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
    animation: imageAnimation 36s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
    background-image: url('/Content/Images/login/01.png');
}

.cb-slideshow li:nth-child(2) span {
    background-image: url('/Content/Images/login/02.png');
    animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) span {
    background-image: url('/Content/Images/login/03.png');
    animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) span {
    background-image: url('/Content/Images/login/04.png');
    animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) span {
    background-image: url('/Content/Images/login/05.png');
    animation-delay: 24s;
}

.cb-slideshow li:nth-child(6) span {
    background-image: url('/Content/Images/login/06.png');
    animation-delay: 30s;
}

.no-cssanimations .cb-slideshow li span {
    opacity: 1;
}
