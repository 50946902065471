﻿body {
    background-color: $color-body;
    background: $background-body;
    @include background-size(cover);
    min-height: 100vh;
    overflow-y: scroll;

    &.onsite-mode {
        .hidden-onsite {
            display: none;
        }

        .invisible-onsite {
            visibility: hidden;
        }
    }
}

p {
    margin: 0;
}

img {
    @include img-responsive;
}

textarea {
    resize: vertical;
    min-height: 85px;
}

.fa, .fas, .far, .fal, .fab {
    &.text-on-right {
        margin-right: 10px;
    }
}

.fa, .fas, .far, .fal, .fab {
    &.text-on-left {
        margin-left: 10px;
    }
}

dl {
    @extend .dl-horizontal;

    dt {
    }

    dd {
        margin-bottom: 10px;
    }
}

a {
    &:hover {
        cursor: pointer;
    }
}

ul {
    &.hide-bullets {
        list-style: none;
        padding-left: 2rem;
    }
}

[data-field-visibility="ShowInExtended"] {
    display: none;
}

.extended-fields-visible {
    [data-field-visibility="ShowInExtended"] {
        display: inherit;
    }
}

.center-content {
    text-align: center;
}
