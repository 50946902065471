﻿
button, .button,
.page-content-container button {
    @extend .btn;
    @extend .btn-default;
    font-weight: $font-weight-body;
    border-radius: $border-radius-button;
    border-width: 1px;
    border-style: solid;
    padding: $padding-vert-button $padding-horiz-button;
    text-shadow: none;
    opacity: 1;

    &:hover, &:focus {
        opacity: 1;
    }

    &.small {
        @extend .btn-xs;
        padding: $padding-vert-button-small $padding-horiz-button-small;
    }

    &.block {
        display: block;
        width: 100%;
    }

    &::before {
        margin-right: 5px;
    }

    &.icon-only::before {
        margin-right: 0;
    }

    &[type="submit"] {
        @extend .btn-primary;

        &:before {
            @extend .fal;
            @extend .fa-check-square;
        }

        &.save {
            &:before {
                @extend .fal;
                @extend .fa-save;
            }
        }

        &.advanced-create {
            @extend .btn-info;

            &:before {
                @extend .fal;
                @extend .fa-plus-hexagon;
            }
        }
    }

    &.primary, &.btn-primary {
        @extend .btn-primary;
    }

    &.info, &.btn-info {
        @extend .btn-info;
    }

    &.success, &.btn-success {
        @extend .btn-success;
    }

    &.warning, &.btn-warning {
        @extend .btn-warning;
    }

    &.danger, &.btn-danger {
        @extend .btn-danger;
    }

    &.search {
        @extend .btn-info;

        &:before {
            @extend .fal;
            @extend .fa-search;
        }
    }

    &.add {
        @extend .btn-success;

        &:before {
            @extend .fal;
            @extend .fa-plus-circle;
        }

        &.opportunity {
            &:before {
                @extend .icon-opportunity !optional;
            }
        }

        &.deliverynote {
            &:before {
                @extend .icon-deliverynote !optional;
            }
        }

        &.purchaseorder {
            &:before {
                @extend .icon-purchaseorder !optional;
            }
        }

        &.salesorder {
            &:before {
                @extend .icon-salesorder !optional;
            }
        }
    }

    &.enable {
        @extend .btn-success;

        &:before {
            @extend .fal;
            @extend .fa-toggle-on;
        }
    }

    &.edit {
        &:before {
            @extend .fal;
            @extend .fa-pencil-alt;
        }
    }

    &.delete, &.remove {
        @extend .btn-danger;

        &:before {
            @extend .fal;
            @extend .fa-trash-alt;
        }
    }

    &.disable {
        @extend .btn-danger;

        &:before {
            @extend .fal;
            @extend .fa-toggle-off;
        }
    }

    &.close {
        &:before {
            @extend .fal;
            @extend .fa-times;
        }

        &.servicevisit {
            @extend .btn-success;
        }
    }

    &.reopen {
        &:before {
            @extend .fal;
            @extend .fa-undo;
        }

        &.servicevisit {
            @extend .btn-warning;
        }
    }

    &.cancel {
        &:before {
            @extend .fal;
            @extend .fa-times-hexagon;
        }
    }

    &.publish {
        @extend .btn-primary;

        &:before {
            @extend .fal;
            @extend .fa-cloud-upload;
        }
    }

    &.view {
        &:before {
            @extend .fal;
            @extend .fa-eye;
        }
    }

    &.back,
    &.forward {
        @extend .btn-primary;

        &.back {
            &:before {
                @extend .fal;
                @extend .fa-arrow-alt-left;
            }
        }

        &.forward {
            &:before {
                @extend .fal;
                @extend .fa-arrow-alt-right;
            }
        }
    }

    &.goto-list {
        &:before {
            @extend .fal;
            @extend .fa-th-list;
        }
    }

    &.goto-home {
        @extend .btn-info;

        &:before {
            @extend .fal;
            @extend .fa-home;
        }
    }

    &.switch {
        @extend .btn-info;

        &:before {
            @extend .fal;
            @extend .fa-exchange;
        }
    }

    &.move-up {
        &:before {
            @extend .fal;
            @extend .fa-arrow-up;
        }
    }

    &.move-down {
        &:before {
            @extend .fal;
            @extend .fa-arrow-down;
        }
    }

    &.email,
    &.invite {
        &:before {
            @extend .icon-email !optional;
        }
    }

    &.email-address {
        &:before {
            @extend .icon-email-address !optional;
        }
    }

    &.merge {
        &:before {
            @extend .fal;
            @extend .fa-compress-alt;
        }
    }

    &.download {
        &:before {
            @extend .fal;
            @extend .fa-download;
        }
    }

    &.download-pdf {
        &:before {
            @extend .fal;
            @extend .fa-file-pdf;
        }
    }

    &.upload {
        &:before {
            @extend .icon-upload !optional;
        }
    }

    &.password {
        &:before {
            @extend .icon-password !optional;
        }
    }

    &.termsandconditions {
        @extend .btn-info;

        &:before {
            @extend .icon-termsandconditions !optional;
        }

        &.make-default, &.is-default {
            &:before {
                @extend .fa-star;
            }
        }

        &.make-default {
            &:before {
                @extend .fal;
            }
        }

        &.is-default {
            &:before {
                @extend .fas;
            }
        }
    }

    &.mark-as-received {
        @extend .btn-info;

        &:before {
            @extend .fal;
            @extend .fa-hand-receiving;
        }
    }

    &.clone-document {
        @extend .btn-info;

        &:before {
            @extend .fal;
            @extend .fa-copy;
        }
    }

    &.print {
        &:before {
            @extend .fal;
            @extend .fa-print;
        }
    }

    &.field-history {
        @extend .icon-only;

        &:before {
            @extend .fal;
            @extend .fa-history;
        }
    }

    &.enable-user {
        @extend .success;

        &:before {
            @extend .icon-enable-user !optional;
        }
    }

    &.disable-user {
        @extend .danger;

        &:before {
            @extend .icon-disable-user !optional;
        }
    }

    &.connect-to-xero,
    &.add-to-xero,
    &.view-in-xero {
        &::before {
            display: inline-block;
            content: ' ';
            background-image: url('/content/images/xero-logo.png');
            background-size: 100%;
            vertical-align: middle;
            width: 26px;
            height: 26px;
            margin-top: -5px;
            margin-bottom: -4px;
        }
    }
    // DEFINE THIS LAST
    &.working,
    &.working.close {
        &:before {
            @extend .fal;
            @extend .fa-sync;
        }
    }
}

.modal-header {
    button.close::before {
        margin: 0;
    }
}

a.toggle {
    &:hover {
        cursor: pointer;
    }
}

@media screen and (min-width: $screen-sm-min) {
    .note-container .delete-container,
    .panel .footer .delete-container,
    table tr td button.delete {
        @include desktop-invisible;
    }

    .note-container,
    .panel .footer,
    table tr {
        &:hover {
            .delete-container,
            button.delete {
                opacity: 1;
            }
        }
    }
}

.cant-delete {
    .note-container, .panel {
        .footer, .panel-footer {
            .delete-container {
                display: none;
            }
        }
    }
}
