﻿/* ### FADE IN ### */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* ### SLIDE DOWN ### */
@keyframes slideDown {
	from { transform: translateY(-100%); }
	to { transform: translateY(0%); }
}

@-webkit-keyframes slideDown {
	from { -webkit-transform: translateY(-100%); }
	to { -webkit-transform: translateY(0%); }
}