
@import "Sass/variables";
@import "Sass/bootstrap-variables";

$font-family-body: 'Open Sans', sans-serif;
$font-family-title: 'Exo 2', sans-serif;

$background-body: #000000;

/* -- Vendors -- */
/* ------------- */

// Bootstrap - Core variables and mixins
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Bootstrap - Reset and dependencies
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/print";

// Bootstrap - Core CSS
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "Vendor/bootstrap-sass/assets/stylesheets/bootstrap/buttons";


/* -- Our Stuff -- */
/* --------------- */

@import "Sass/Mixin/mixin";
@import "Sass/Base/base";
@import "Sass/Layout/layout";

@import "Sass/Module/button";
@import "Sass/Module/form";
@import "Sass/Module/cb-slideshow";


/* -- Login Stuff -- */
/* ----------------- */

.login-form-container {
    margin-top: 100px;
    background: #000000;
    background: rgba(0, 0, 0, 0.5);
    border: 3px solid #000000;
    color: #FFFFFF;
    padding: 20px 25px !important;
    text-align: center;
    border-radius: 50px;

    @media screen and (max-width: $screen-sm-max) {
        width: 90%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .panel-header {
        margin-bottom: 25px;
    }

    form {
        .form-group {
            input[type="email"],
            input[type="password"] {
                @include form-control-focus($color-primary);
                border-radius: 10px;
                height: 46px;
                color: #FFFFFF;
                text-align: inherit;
                border: 2px solid rgba(255, 255, 255, 0.2);
                background: rgba(0, 0, 0, 0.5);
            }
        }

        .panel-footer {
            margin-top: 30px;

            button[type="submit"] {
                width: 75%;
                font-size: 18px;
                padding-top: 12px;
                padding-bottom: 12px;
                border-radius: 10px;
                font-family: $font-family-title;

                &:before {
                    display: none;
                }
            }

            .forgotten-password {
                color: #FFFFFF;
                display: block;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}
