﻿
// Setting brand to theme colours

$btn-primary-color: $color-primary-fg;
$brand-primary: $color-primary;

$btn-success-color: $color-success-fg;
$brand-success: $color-success;

$btn-info-color: $color-info-fg;
$brand-info: $color-info;

$btn-warning-color: $color-warning-fg;
$brand-warning: $color-warning;

$btn-danger-color: $color-danger-fg;
$brand-danger: $color-danger;

$fa-font-path: '/Content/CustomVendor/FontAwesome5/webfonts';
